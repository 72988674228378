import { ChakraTheme } from "@chakra-ui/react";

type Typography = Pick<
    Partial<ChakraTheme>,
    "fonts" | "fontSizes" | "fontWeights"
>;

const typography = {
    fonts: {
        heading: "'Monteserrat', sans-serif",
        body: "'Monteserrat', sans-serif",
        mono: "'Monteserrat', sans-serif",
    },
} as Typography;

export default typography;
