import React from "react";
import {
    TopToolbar,
    ExportButton,
    CreateButton,
    ListActionsProps,
} from "react-admin";

const ListActions: React.FC<ListActionsProps> = ({ basePath, resource }) => {
    return (
        <TopToolbar>
            <CreateButton resource={resource} basePath={basePath} />
            <ExportButton resource={resource} basePath={basePath} />
        </TopToolbar>
    );
};

export default ListActions;
