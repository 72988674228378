import React from "react";
import { useRecordContext, FieldProps } from "react-admin";
import { Box } from "@chakra-ui/react";
import { useMedia } from "src/helpers";
import { RAFile } from "src/types";

interface TruncatedUrlFieldProps extends Pick<FieldProps, "source"> {}
const TruncatedUrlField: React.FC<TruncatedUrlFieldProps> = ({ source }) => {
    const record = useRecordContext();

    const { isMobile } = useMedia();

    const RALink = record[source as string] as string | RAFile;

    const link = typeof RALink === "string" ? RALink : RALink.url;

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        window.open(link, "_target");
    };

    return (
        <Box
            target="_blank"
            color="blue.600"
            onClick={handleClick}
            style={{
                maxWidth: isMobile ? 250 : 350,
                width: "fit-content",
                display: "block",
                whiteSpace: "pre",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            {link}
        </Box>
    );
};

export default TruncatedUrlField;
