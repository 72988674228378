import React from "react";
import "./scss/main.scss";
import { ChakraProvider } from "./providers/context";
import { Admin, Resource } from "react-admin";
import {
    PortfolioItemsList,
    PortfoioItemsEdit,
    PortfolioItemsCreate,
    CvList,
    CvShow,
    CvEdit,
    CvCreate,
    TestimonialsList,
    TestimonialsEdit,
    TestimonialsCreate,
} from "./views";
import { CvS, PORTFOLIO_ITEM, TESTIMONIALS } from "./constants";
import ApiDataProvider from "./providers/data";
import ApiAuthProvider from "./providers/auth";
import {
    CgBriefcase as PortfolioIcon,
    CgFileDocument as CVIcon,
    CgComment as TestimonialsIcon,
} from "react-icons/cg";

function App() {
    return (
        <ChakraProvider>
            <Admin
                dataProvider={ApiDataProvider}
                authProvider={ApiAuthProvider}
            >
                <Resource
                    name={PORTFOLIO_ITEM}
                    icon={() => <PortfolioIcon size={20} />}
                    list={PortfolioItemsList}
                    edit={PortfoioItemsEdit}
                    create={PortfolioItemsCreate}
                />
                <Resource
                    name={CvS}
                    icon={() => <CVIcon size={20} />}
                    list={CvList}
                    show={CvShow}
                    edit={CvEdit}
                    create={CvCreate}
                />
                <Resource
                    name={TESTIMONIALS}
                    icon={() => <TestimonialsIcon size={20} />}
                    list={TestimonialsList}
                    edit={TestimonialsEdit}
                    create={TestimonialsCreate}
                />
            </Admin>
        </ChakraProvider>
    );
}

export default App;
