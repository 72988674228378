export const AdminRoutes = {
    LOGIN: "admin/login",
    VERIFY: "admin/verify",
    CREATE: "admin",
};

export const PortfolioItemRoutes = {
    GET_ALL: "portfolio/item",
    GET_ONE: "portfolio/item/:id",
    CREATE: "portfolio/item",
    UPDATE: "portfolio/item/:id",
    DELETE: "portfolio/item/:id",
    SEARCH: "portfolio/item/search",
};

export const CvRoutes = {
    GET_PRIMARY: "portfolio/cv/primary",
    GET_ONE: "portfolio/cv/:id",
    GET_ALL: "portfolio/cv",
    UPDATE: "portfolio/cv/:id",
    CREATE: "portfolio/cv",
    DELETE: "portfolio/cv/:id",
};

export const TestimonialsRoutes = {
    GET_ALL: "testimonial",
    GET_TOP: "testimonial/top",
    GET_ONE: "testimonial/:id",
    CREATE: "testimonial",
    UPDATE: "testimonial/:id",
    DELETE: "testimonial/:id",
};

export const MailRoutes = {
    BASE: "mail",
    ADMIN: "admin",
};
