import React from "react";
import { Edit as RAEdit, EditProps as RAEditProps } from "react-admin";
import { CvEdit } from "src/components/templates";

const Edit: React.FC<RAEditProps> = ({ ...props }) => {
    return (
        <RAEdit {...props}>
            <CvEdit />
        </RAEdit>
    );
};

export default Edit;
