import { Box } from "@chakra-ui/react";
import React from "react";
import { FieldProps, useRecordContext } from "react-admin";
import { CgCheckO as CheckIcon, CgCloseO as CloseIcon } from "react-icons/cg";

interface BooleanFieldProps extends Pick<FieldProps, "source"> {}

const BooleanField: React.FC<BooleanFieldProps> = ({ source }) => {
    const record = useRecordContext();

    const isTrue = !!record[source as string];

    return (
        <Box>
            {isTrue ? (
                <CheckIcon size={20} color="lightgreen" />
            ) : (
                <CloseIcon size={20} color="pink" />
            )}
        </Box>
    );
};

export default BooleanField;
