import React from "react";
import { Box, Image, Heading, BoxProps } from "@chakra-ui/react";

export interface CardProps extends BoxProps {
    title: string;
    image?: string;
    createdAt?: string;
}

const Card: React.FC<CardProps> = ({ title, image, createdAt, ...props }) => {
    return (
        <Box
            borderRadius="md"
            boxShadow="lg"
            height={400}
            width={300}
            overflow="hidden"
            cursor="pointer"
            transition="250ms ease-out"
            _hover={{
                boxShadow: "2xl",
                transform: "perspective(300px) translateZ(10px)",
            }}
            {...props}
        >
            <Image src={image} height="70%" width="100%" objectFit="cover" />
            <Box paddingInline={5}>
                {createdAt && (
                    <Heading
                        as="h6"
                        textAlign="right"
                        fontSize="smaller"
                        color="gray.400"
                        mt={3}
                    >
                        {new Date(createdAt).toLocaleDateString()}
                    </Heading>
                )}
                <Heading
                    as="h5"
                    textAlign="center"
                    mt={5}
                    textTransform="capitalize"
                    fontSize="xl"
                    whiteSpace="pre"
                    maxW="100%"
                    overflow="hidden"
                    textOverflow="ellipsis"
                >
                    {title}
                </Heading>
            </Box>
        </Box>
    );
};

export default Card;
