import { BASE_URL } from "src/api/constants/base";
import axios, { AxiosInstance } from "axios";
import { AUTH_TOKEN_KEY } from "src/constants";

//Fetch client API
class Client {
    constructor() {
        //add request interceptors for the two instances
        this.addRequestInterceptors(this.instance);
        this.addRequestInterceptors(this.uploadSupportableInstance);
    }

    //axios base instance [JSON]
    protected instance = axios.create({
        baseURL: BASE_URL,
        headers: {
            "Content-Type": "application/json",
        },
    });

    //axios base instance [MULTIPART FORM-DATA]
    protected uploadSupportableInstance = axios.create({
        baseURL: BASE_URL,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    //add request interceptors for instances
    addRequestInterceptors(instance: AxiosInstance) {
        instance.interceptors.request.use((config) => {
            //add authorization header
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${window.localStorage.getItem(
                    AUTH_TOKEN_KEY,
                )}`,
            };
            return config;
        });
    }
}

export default Client;
