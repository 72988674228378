import { useToken, useMediaQuery } from "@chakra-ui/react";

const useMedia = () => {
    //get breakpoints
    const [sm, md, lg, xl] = useToken("breakpoints", ["sm", "md", "lg", "xl"]);

    //Media queries
    const [
        isLargeMobileQuery,
        isTabletQuery,
        isDesktopQuery,
        isLargeScreenQuery,
    ] = useMediaQuery([
        `(min-width: ${sm})`,
        `(min-width: ${md})`,
        `(min-width: ${lg})`,
        `(min-width: ${xl})`,
    ]);

    //determine screen variants
    const isMobile = !isLargeMobileQuery;
    const isLargeMobile = isLargeMobileQuery && !isTabletQuery;
    const isTablet = isTabletQuery && !isDesktopQuery;
    const isDesktop = isDesktopQuery && !isLargeScreenQuery;
    const isLargeScreen = isLargeScreenQuery;

    //boolean helpers
    return {
        isMobile,
        isLargeMobile,
        isTablet,
        isDesktop,
        isLargeScreen,
        isLargerThanOrIsMobile: isLargeMobileQuery,
        isLargerThanOrIsTablet: isTabletQuery,
        isLargerThanOrIsDesktop: isDesktopQuery,
        isLargerThanOrIsLargeScreen: isLargeScreenQuery,
    };
};

export default useMedia;
