import React from "react";
import { FieldProps, useRecordContext } from "react-admin";
import { AiFillStar as StarIcon } from "react-icons/ai";
import { Box } from "@chakra-ui/react";

const RatingField: React.FC<FieldProps> = ({ source }) => {
    const record = useRecordContext();

    const rate = record[source as string] as number;

    return (
        <Box d="flex" alignItems="center">
            {Array(rate)
                .fill(0)
                .map((_, index) => (
                    <StarIcon key={index} color="gold" />
                ))}
        </Box>
    );
};

export default RatingField;
