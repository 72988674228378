import { ChakraTheme } from "@chakra-ui/react";
import chroma from "chroma-js";

//Mapper that should produce chakra colors shape
const getThemeValue = (colors: string[]) =>
    Object.fromEntries(
        colors.map((color, index) => [(index + 1) * 100, color]),
    );

//Colors palette length
const SCALE = 9;

//Colors scales
const primaryColor = chroma.scale(["#93bfed", "#1969bf"]).colors(SCALE);

//Colors theme
const colors = {
    primary: getThemeValue(primaryColor),
} as ChakraTheme["colors"];

export default colors;
