import React from "react";
import {
    Create as RACreate,
    CreateProps as RACreateProps,
    SelectInput,
    SimpleForm,
    TextInput,
} from "react-admin";
import { TestimonialFields } from "src/api/constants";

const Create: React.FC<RACreateProps> = ({ ...props }) => {
    return (
        <RACreate {...props}>
            <SimpleForm>
                <TextInput source={TestimonialFields.name} />
                <SelectInput
                    source={TestimonialFields.rate}
                    choices={Array(5)
                        .fill(0)
                        .map((_, idx) => ({
                            id: idx + 1,
                            name: `${idx + 1}`,
                        }))}
                />
                <TextInput multiline source={TestimonialFields.content} />
            </SimpleForm>
        </RACreate>
    );
};

export default Create;
