import React from "react";
import { Tag, TagLabel } from "@chakra-ui/react";

interface QuickFilterProps {
    label?: string;
    source?: string;
    defaultValue?: unknown;
}

const QuickFilter: React.FC<QuickFilterProps> = ({ label }) => {
    return (
        <Tag
            label={label}
            mb={2}
            minW="80px"
            textAlign="center"
            padding={2}
            borderRadius="full"
        >
            <TagLabel m="0 auto" textTransform="capitalize" color="gray.500">
                {label}
            </TagLabel>
        </Tag>
    );
};

export default QuickFilter;
