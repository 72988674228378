import { AuthProvider } from "react-admin";
import { AuthClient } from "src/api/client";
import { AUTH_TOKEN_KEY } from "src/constants";
import { Credentials } from "src/types";

//api client for querying/mutating auth resources
const client = new AuthClient();

export default {
    login(credentials: Credentials) {
        return new Promise(async (resolve, reject) => {
            const admin = await client.auth.loginAdmin(credentials);
            if (admin)
                resolve(
                    window.localStorage.setItem(
                        AUTH_TOKEN_KEY,
                        admin.accessToken,
                    ),
                );
            //Save the token for later verification
            else reject(); //No admin with such credentials
        });
    },
    checkAuth() {
        const token = window.localStorage.getItem(AUTH_TOKEN_KEY); //auth token
        if (token) return Promise.resolve();
        //token exists, user is authenticated
        else return Promise.reject(); //no token.. Logout
    },
    getPermissions() {
        return Promise.resolve([]); //No permissions currently
    },
    checkError(error: Error) {
        //Parse axios response error string to get status code
        const statusCode = +error.message.split("status code")[1];
        //Logout on unauthorized responses only
        if (statusCode === 401) return Promise.reject();
        return Promise.resolve();
    },
    logout() {
        window.localStorage.removeItem(AUTH_TOKEN_KEY); //remove token
        return Promise.resolve(); //logout
    },
    getIdentity() {
        try {
            //Hard coded.. Only one admin and it'ms Meeee
            return Promise.resolve({
                fullName: "Eldababa",
                avatar: "/images/avatar.svg",
            });
        } catch (e) {
            return Promise.reject(e);
        }
    },
} as AuthProvider;
