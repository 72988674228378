import { Box, BoxProps, Text } from "@chakra-ui/react";
import React from "react";

interface PanelProps extends BoxProps {
    content?: string;
}

const Panel: React.FC<PanelProps> = ({ content, ...props }) => {
    return (
        <Box padding={5} bg="#f7f8fa" color="gray.500" {...props}>
            <Text>{content}</Text>
        </Box>
    );
};

export default Panel;
