import React, { useState } from "react";
import { SimpleForm, FileInput } from "react-admin";
import { CvFields } from "src/api/constants";
import { PDFViewer } from "src/components/modules";

const CvEdit = ({ ...props }) => {
    //file preview
    const [filePreview, setFilePreview] = useState("");

    //file upload change handler
    const handleChange = (file: File) => {
        if (!file) return setFilePreview("");
        const objectUrl = URL.createObjectURL(file);
        setFilePreview(objectUrl);
    };

    return (
        <SimpleForm {...props}>
            <FileInput source={CvFields.file} onChange={handleChange}>
                <PDFViewer url={filePreview} />
            </FileInput>
        </SimpleForm>
    );
};

export default CvEdit;
