import { DataClient } from "src/api/client";
import {
    GetListParams,
    GetListResult,
    GetOneResult,
    GetOneParams,
} from "react-admin";
import { ApiListToRAList } from "src/api/mappers";
import { withId } from "src/helpers";

export const getCvs = async (
    client: DataClient,
    params: GetListParams,
): Promise<GetListResult> => {
    const cvs = await client.cv.getList();
    const response = ApiListToRAList(cvs);
    return response;
};

export const getCv = async (
    client: DataClient,
    params: GetOneParams,
): Promise<GetOneResult> => {
    const cv = await client.cv.getOne(params.id.toString());
    return { data: withId(cv) };
};
