import Client from "../base";
import { PortfolioItem, Cv, Testimonial } from "../types";
import {
    CvRoutes,
    PortfolioItemRoutes,
    TestimonialsRoutes,
} from "src/api/constants";
import { JSONToFormData, resolveURL } from "src/helpers";

class DataClient extends Client {
    //portfolio item resources
    portfolioItem: PortfolioItem = {
        getList: async (params) => {
            const response = await this.instance.get(
                PortfolioItemRoutes.GET_ALL,
                {
                    params,
                },
            );
            return response.data;
        },
        search: async (params) => {
            const response = await this.instance.get(
                PortfolioItemRoutes.SEARCH,
                {
                    params: {
                        query: params.title,
                    },
                },
            );
            return response.data;
        },
        updateOne: async (query) => {
            const response = await this.uploadSupportableInstance.put(
                resolveURL(PortfolioItemRoutes.UPDATE, {
                    id: query._id,
                }),
                JSONToFormData(query),
            );
            return response.data;
        },
        getOne: async (id) => {
            const response = await this.instance.get(
                resolveURL(PortfolioItemRoutes.GET_ONE, { id }),
            );
            return response.data;
        },
        deleteOne: async (id) => {
            const response = await this.instance.delete(
                resolveURL(PortfolioItemRoutes.DELETE, { id }),
            );
            return response.data;
        },
        create: async (params) => {
            const response = await this.uploadSupportableInstance.post(
                PortfolioItemRoutes.CREATE,
                JSONToFormData(params),
            );
            return response.data;
        },
    };

    //cv resources
    cv: Cv = {
        getList: async () => {
            const response = await this.instance.get(CvRoutes.GET_ALL);
            return response.data;
        },
        getPrimary: async () => {
            const response = await this.instance.get(CvRoutes.GET_PRIMARY);
            return response.data;
        },
        getOne: async (id) => {
            const response = await this.instance.get(
                resolveURL(CvRoutes.GET_ONE, { id }),
            );
            return response.data;
        },
        updateOne: async (query) => {
            const response = await this.uploadSupportableInstance.put(
                resolveURL(CvRoutes.UPDATE, { id: query._id }),
                JSONToFormData(query),
            );
            return response.data;
        },
        deleteOne: async (id) => {
            const response = await this.instance.delete(
                resolveURL(CvRoutes.DELETE, { id }),
            );
            return response.data;
        },
        create: async (payload) => {
            const response = await this.uploadSupportableInstance.post(
                CvRoutes.CREATE,
                JSONToFormData(payload),
            );
            return response.data;
        },
    };

    testimonial: Testimonial = {
        getList: async () => {
            const response = await this.instance.get(
                TestimonialsRoutes.GET_ALL,
            );
            return response.data;
        },
        getOne: async (id) => {
            const response = await this.instance.get(
                resolveURL(TestimonialsRoutes.GET_ONE, { id: id }),
            );
            return response.data;
        },
        getTop: async () => {
            const response = await this.instance.get(
                TestimonialsRoutes.GET_TOP,
            );
            return response.data;
        },
        create: async (payload) => {
            const response = await this.instance.post(
                TestimonialsRoutes.CREATE,
                payload,
            );
            return response.data;
        },
        updateOne: async (query) => {
            const response = await this.instance.put(
                resolveURL(TestimonialsRoutes.UPDATE, { id: query._id }),
                query,
            );
            return response.data;
        },
        deleteOne: async (id) => {
            const response = await this.instance.delete(
                resolveURL(TestimonialsRoutes.DELETE, { id }),
            );
            return response.data;
        },
    };
}

export default DataClient;
