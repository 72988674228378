import { DataClient } from "src/api/client";
import { withId } from "src/helpers/mongo";
import { WithId } from "src/helpers/mongo/types";
import {
    CreateParams,
    CreateResult,
    DeleteParams,
    DeleteResult,
    UpdateParams,
    UpdateResult,
} from "react-admin";
import { Testimonial } from "src/types";

export const updateTestimonial = async (
    client: DataClient,
    params: UpdateParams<WithId<Testimonial>>,
): Promise<UpdateResult<WithId<Testimonial>>> => {
    const { data } = params;
    const response = await client.testimonial.updateOne(data);
    return { data: withId(response) };
};

export const createTestimonial = async (
    client: DataClient,
    params: CreateParams<WithId<Testimonial>>,
): Promise<CreateResult<WithId<Testimonial>>> => {
    const { data } = params;
    const response = await client.testimonial.create(data);
    return { data: withId(response) };
};

export const deleteTestimonial = async (
    client: DataClient,
    params: DeleteParams,
): Promise<DeleteResult<WithId<Testimonial>>> => {
    const { id } = params;
    const response = await client.testimonial.deleteOne(id.toString());
    return { data: withId(response) };
};
