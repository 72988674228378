import { Box, BoxProps } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { PDFDocumentProxy } from "react-pdf/node_modules/pdfjs-dist/types/src/display/api";

interface PDFWorkerProps extends BoxProps {
    url?: string;
}

const PDFViewer: React.FC<PDFWorkerProps> = ({ url, ...props }) => {
    //pdf file
    const file = useMemo(() => url, [url]);

    //total number of pages for provided PDF file
    const [total, setTotal] = useState(0);

    //successfully loaded handler
    const handleLoaded = (e: PDFDocumentProxy) => {
        setTotal(e.numPages);
    };

    if (!file) return <></>;

    return (
        <Box
            paddingBlock={5}
            overflow="hidden"
            maxW="90vw"
            margin="0 auto"
            {...props}
        >
            <Box maxH={500} overflow="auto" boxShadow="lg" w="100%">
                <Document file={file} onLoadSuccess={handleLoaded}>
                    {Array(total)
                        .fill(0)
                        .map((_, index) => (
                            <Page
                                key={index}
                                pageIndex={index}
                                pageNumber={index + 1}
                            />
                        ))}
                </Document>
            </Box>
        </Box>
    );
};

export default React.memo(PDFViewer);
