import React from "react";
import { Box } from "@chakra-ui/react";
import { useListContext, Link } from "react-admin";
import { Card } from "src/components/modules";
import { PortfolioItem } from "src/types";
import { WithId } from "src/helpers";
import { PORTFOLIO_ITEM } from "src/constants";

const PortfolioItemList = () => {
    const { ids, data } = useListContext<WithId<PortfolioItem>>();

    return (
        <Box
            d="grid"
            gridTemplateColumns={[1, 1, 2, 2, 3].map(
                (count) => `repeat(${count}, 1fr)`,
            )}
            padding={5}
            sx={{ gap: 5 }}
        >
            {ids.map((id) => (
                <Link key={id} to={`/${PORTFOLIO_ITEM}/${id}`}>
                    <Card key={id} {...data[id]} width="unset" />
                </Link>
            ))}
        </Box>
    );
};

export default PortfolioItemList;
