import React from "react";
import {
    Create as RACreate,
    CreateProps as RACreateProps,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
} from "react-admin";
import { PortfolioItemFields } from "src/api/constants";
import { URL } from "src/constants";
import { formatFile } from "src/helpers";

const Create: React.FC<RACreateProps> = ({ ...props }) => {
    return (
        <RACreate {...props}>
            <SimpleForm>
                <TextInput source={PortfolioItemFields.title} />
                <TextInput source={PortfolioItemFields.description} />
                <ImageInput
                    format={formatFile}
                    source={PortfolioItemFields.image}
                >
                    <ImageField source={URL} />
                </ImageInput>
                <TextInput source={PortfolioItemFields.url} />
            </SimpleForm>
        </RACreate>
    );
};

export default Create;
