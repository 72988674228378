import Client from "../base";
import { Auth } from "../types";
import { AdminRoutes } from "src/api/constants";

class AuthClient extends Client {
    auth: Auth = {
        loginAdmin: async (credentials) => {
            const response = await this.instance.post(
                AdminRoutes.LOGIN,
                credentials,
            );
            return response.data;
        },
        verifyAdmin: async () => {
            const response = await this.instance.get(AdminRoutes.VERIFY);
            return response.data;
        },
    };
}

export default AuthClient;
