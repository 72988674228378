import React from "react";
import { Create as RACreate, CreateProps as RACreateProps } from "react-admin";
import { CvCreate } from "src/components/templates";

const Create: React.FC<RACreateProps> = ({ ...props }) => {
    return (
        <RACreate {...props}>
            <CvCreate />
        </RACreate>
    );
};

export default Create;
