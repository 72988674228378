import React from "react";
import {
    DateInput,
    Edit as RAEdit,
    EditProps as RAEditProps,
    SelectInput,
    SimpleForm,
    TextInput,
} from "react-admin";
import { TestimonialFields } from "src/api/constants";

const Edit: React.FC<RAEditProps> = ({ ...props }) => {
    return (
        <RAEdit {...props}>
            <SimpleForm>
                <TextInput source={TestimonialFields.name} />
                <SelectInput
                    source={TestimonialFields.rate}
                    choices={Array(5)
                        .fill(0)
                        .map((_, idx) => ({
                            id: idx + 1,
                            name: `${idx + 1}`,
                        }))}
                />
                <TextInput multiline source={TestimonialFields.content} />
                <DateInput source={TestimonialFields.createdAt} />
            </SimpleForm>
        </RAEdit>
    );
};

export default Edit;
