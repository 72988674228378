import { DataProvider } from "react-admin";
import { DataClient } from "src/api/client";
import {
    getPortfolioItems,
    getPortfolioItem,
    getCvs,
    getCv,
    getTestimonials,
    getTestimonial,
} from "src/api/queries";
import {
    createPortfolioItem,
    deletePortfolioItem,
    updatePortfolioItem,
    createCv,
    deleteCv,
    updateCv,
    updateTestimonial,
    createTestimonial,
    deleteTestimonial,
} from "src/api/mutations";
import { CvS, PORTFOLIO_ITEM, TESTIMONIALS } from "src/constants";

//data client for querying/mutating api resources
const client = new DataClient();

export default {
    getList(resource, params) {
        const CTA = {
            [PORTFOLIO_ITEM]: getPortfolioItems,
            [CvS]: getCvs,
            [TESTIMONIALS]: getTestimonials,
        } as Record<typeof resource, Function>;
        return CTA[resource](client, params);
    },
    getOne(resource, params) {
        const CTA = {
            [PORTFOLIO_ITEM]: getPortfolioItem,
            [CvS]: getCv,
            [TESTIMONIALS]: getTestimonial,
        } as Record<typeof resource, Function>;
        return CTA[resource](client, params);
    },
    create(resource, params) {
        const CTA = {
            [PORTFOLIO_ITEM]: createPortfolioItem,
            [CvS]: createCv,
            [TESTIMONIALS]: createTestimonial,
        } as Record<typeof resource, Function>;
        return CTA[resource](client, params);
    },
    update(resource, params) {
        const CTA = {
            [PORTFOLIO_ITEM]: updatePortfolioItem,
            [CvS]: updateCv,
            [TESTIMONIALS]: updateTestimonial,
        } as Record<typeof resource, Function>;
        return CTA[resource](client, params);
    },
    delete(resource, params) {
        const CTA = {
            [PORTFOLIO_ITEM]: deletePortfolioItem,
            [CvS]: deleteCv,
            [TESTIMONIALS]: deleteTestimonial,
        } as Record<typeof resource, Function>;
        return CTA[resource](client, params);
    },
} as DataProvider;
