/* Resource fields */

export const PortfolioItemFields = {
    id: "_id",
    title: "title",
    url: "url",
    description: "description",
    image: "image",
    createdAt: "createdAt",
};

export const CvFields = {
    id: "_id",
    file: "file",
    primary: "primary",
    lastUpdatedAt: "lastUpdatedAt",
    uploadedAt: "uploadedAt",
};

export const TestimonialFields = {
    id: "_id",
    name: "name",
    content: "content",
    rate: "rate",
    createdAt: "createdAt",
};
