import React from "react";
import { Show, ShowProps } from "react-admin";
import { CvShow as CvShowTemplate } from "src/components/templates";
import { ShowActions } from "src/components/actions";

const CvShow: React.FC<ShowProps> = ({ ...props }) => {
    return (
        <Show {...props} actions={<ShowActions />}>
            <CvShowTemplate />
        </Show>
    );
};

export default CvShow;
