import { withId, WithId } from "src/helpers";
import {
    GetListParams,
    GetListResult,
    GetOneParams,
    GetOneResult,
} from "react-admin";
import { Testimonial } from "src/types";
import { DataClient } from "src/api/client";

export const getTestimonials = async (
    client: DataClient,
    params: GetListParams,
): Promise<GetListResult<WithId<Testimonial>>> => {
    if (params.filter.top) {
        const response = await client.testimonial.getTop();
        return { data: withId(response), total: response.length };
    }
    const response = await client.testimonial.getList();
    return { data: withId(response), total: response.length };
};

export const getTestimonial = async (
    client: DataClient,
    params: GetOneParams,
): Promise<GetOneResult<WithId<Testimonial>>> => {
    const { id } = params;
    const response = await client.testimonial.getOne(id.toString());
    return { data: withId(response) };
};
