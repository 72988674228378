import React, { useState } from "react";
import {
    SimpleForm,
    BooleanInput,
    FileInput,
    useRecordContext,
} from "react-admin";
import { CvFields } from "src/api/constants";
import { PDFViewer } from "src/components/modules";
import { WithId } from "src/helpers";
import { Cv } from "src/types/cv";

const CvEdit = ({ ...props }) => {
    //current record being edited
    const data = useRecordContext<WithId<Cv>>();

    //file preview
    const [filePreview, setFilePreview] = useState(data?.file);

    //file upload change handler
    const handleChange = (file: File) => {
        if (!file) return setFilePreview("");
        const objectUrl = URL.createObjectURL(file);
        setFilePreview(objectUrl);
    };

    return (
        <SimpleForm {...props}>
            <FileInput source={CvFields.file} onChange={handleChange}>
                <PDFViewer url={filePreview} />
            </FileInput>
            <BooleanInput source={CvFields.primary} />
        </SimpleForm>
    );
};

export default CvEdit;
