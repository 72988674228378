import { Box } from "@chakra-ui/react";
import React from "react";
import { useShowContext } from "react-admin";
import { WithId } from "src/helpers";
import { Cv } from "src/types/cv";
import { PDFViewer } from "src/components/modules";

const CvShow = () => {
    const { record } = useShowContext<WithId<Cv>>();

    return (
        <Box padding="30px 0">
            <PDFViewer url={record?.file} />
        </Box>
    );
};

export default CvShow;
