import React from "react";
import {
    ShowActionsProps,
    TopToolbar,
    EditButton,
    CreateButton,
    DeleteButton,
} from "react-admin";

const ShowActions: React.FC<ShowActionsProps> = ({
    basePath,
    resource,
    data,
}) => {
    return (
        <TopToolbar>
            <CreateButton resource={resource} basePath={basePath} />
            <EditButton record={data} basePath={basePath} />
            <DeleteButton record={data} basePath={basePath} />
        </TopToolbar>
    );
};

export default ShowActions;
