import React from "react";
import {
    Edit as RAEdit,
    EditProps as RAEditProps,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    DateInput,
} from "react-admin";
import { PortfolioItemFields } from "src/api/constants";
import { URL } from "src/constants";
import { formatFile } from "src/helpers";

const Edit: React.FC<RAEditProps> = ({ ...props }) => {
    return (
        <RAEdit {...props}>
            <SimpleForm>
                <TextInput source={PortfolioItemFields.title} />
                <TextInput source={PortfolioItemFields.description} />
                <ImageInput
                    format={formatFile}
                    source={PortfolioItemFields.image}
                >
                    <ImageField source={URL} />
                </ImageInput>
                <TextInput source={PortfolioItemFields.url} />
                <DateInput source={PortfolioItemFields.createdAt} />
            </SimpleForm>
        </RAEdit>
    );
};

export default Edit;
