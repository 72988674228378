import { GetListParams, GetOneParams, GetOneResult } from "react-admin";
import { DataClient } from "src/api/client";
import { ApiPaginatedToRAList } from "src/api/mappers";
import { withId } from "src/helpers";
import { PortfolioItem, PaginatedResults } from "src/types";

export const getPortfolioItems = async (
    client: DataClient,
    params: GetListParams,
) => {
    //Will contain the fetched items
    let items: PaginatedResults<PortfolioItem>;
    //Check if filter params exist
    const filters = params.filter;
    if (Object.keys(filters).length > 0) {
        items = await client.portfolioItem.search(filters);
    } else {
        //No filters..
        const { page, perPage } = params.pagination;
        items = await client.portfolioItem.getList({
            page,
            limit: perPage,
        });
    }
    //map the response
    const response = ApiPaginatedToRAList(items);
    return response;
};

export const getPortfolioItem = async (
    client: DataClient,
    params: GetOneParams,
): Promise<GetOneResult> => {
    const { id } = params;
    const item = await client.portfolioItem.getOne(id.toString());
    return { data: withId(item) };
};
