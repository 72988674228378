import { GetListResult } from "ra-core";
import { BaseDTO, PaginatedResults } from "src/types";
import { withId, WithId } from "src/helpers";

//Maps paginated API results to react admin list
export const ApiPaginatedToRAList = <T extends BaseDTO>(
    query: PaginatedResults<T>,
): GetListResult<WithId<T>> => {
    return {
        data: withId(query.results),
        total: query.count,
    };
};

//Maps API list query to react admin list
export const ApiListToRAList = <T extends BaseDTO>(
    query: T[],
): GetListResult<WithId<T>> => {
    return {
        data: withId(query),
        total: query.length,
    };
};
