import { PortfolioItem } from "src/types";
import {
    UpdateResult,
    UpdateParams,
    DeleteParams,
    DeleteResult,
    CreateParams,
    CreateResult,
} from "react-admin";
import { DataClient } from "src/api/client";
import { WithId, withId } from "src/helpers";

export const updatePortfolioItem = async (
    client: DataClient,
    params: UpdateParams<WithId<PortfolioItem>>,
): Promise<UpdateResult<WithId<PortfolioItem>>> => {
    const { data } = params;
    const response = await client.portfolioItem.updateOne(data);
    return { data: withId(response) };
};

export const deletePortfolioItem = async (
    client: DataClient,
    params: DeleteParams,
): Promise<DeleteResult<WithId<PortfolioItem>>> => {
    const { id } = params;
    const response = await client.portfolioItem.deleteOne(id.toString());
    return { data: withId(response) };
};

export const createPortfolioItem = async (
    client: DataClient,
    params: CreateParams<WithId<PortfolioItem>>,
): Promise<CreateResult<WithId<PortfolioItem>>> => {
    const { data } = params;
    const response = await client.portfolioItem.create(data);
    return { data: withId(response) };
};
