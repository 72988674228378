import { URL } from "src/constants";
import { RAFile } from "src/types";

//file input formatter
export const formatFile = (val: string | RAFile) => {
    if (!val || typeof val === "string") return { [URL]: val };
    //return an object with url key
    else return val; //file object already contains the url key
};

//transforming json to form data for file uploads
export const JSONToFormData = (json: any) => {
    const formData = new FormData();
    for (const [key, val] of Object.entries(
        json as Record<string, string | RAFile>,
    )) {
        //intercept incoming react-admin uploaded files and fetch the file object
        const fileOrString = (val as RAFile).rawFile ?? (val as string);
        formData.append(key, fileOrString);
    }
    return formData;
};
