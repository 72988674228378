import { extendTheme, ChakraTheme } from "@chakra-ui/react";
import colors from "./colors";
import breakpoints from "./breakpoints";
import config from "./config";
import typography from "./typography";

//Customized chakra theme
const theme = extendTheme({
    colors,
    breakpoints,
    config,
    ...typography,
}) as ChakraTheme;

export default theme;
