import { Box } from "@chakra-ui/react";
import React from "react";
import {
    List as RAList,
    ListProps as RAListProps,
    DateField,
    Datagrid,
    SimpleList,
} from "react-admin";
import { CvFields } from "src/api/constants";
import { ListActions } from "src/components/actions";
import { TruncatedUrlField, BooleanField } from "src/components/fields";
import { useMedia } from "src/helpers";

const List: React.FC<RAListProps> = ({ ...props }) => {
    const { isLargerThanOrIsDesktop } = useMedia();

    return (
        <RAList
            {...props}
            actions={<ListActions />}
            bulkActionButtons={false}
            pagination={false}
        >
            {isLargerThanOrIsDesktop ? (
                <Datagrid rowClick="show">
                    <TruncatedUrlField source={CvFields.file} />
                    <DateField source={CvFields.lastUpdatedAt} />
                    <DateField source={CvFields.uploadedAt} />
                    <BooleanField source={CvFields.primary} />
                </Datagrid>
            ) : (
                <SimpleList
                    primaryText={<TruncatedUrlField source={CvFields.file} />}
                    secondaryText={
                        <DateField source={CvFields.lastUpdatedAt} />
                    }
                    tertiaryText={
                        <Box float="right">
                            <BooleanField source={CvFields.primary} />
                        </Box>
                    }
                    linkType="show"
                />
            )}
        </RAList>
    );
};

export default List;
