import React from "react";
import {
    ListProps as RAListProps,
    List as RAList,
    SearchInput,
} from "react-admin";
import { PortfolioItemList } from "src/components/templates";
import { ListActions } from "src/components/actions";
import { PortfolioItemFields } from "src/api/constants";

const List: React.FC<RAListProps> = ({ ...props }) => {
    return (
        <RAList
            {...props}
            filters={[
                <SearchInput source={PortfolioItemFields.title} alwaysOn />,
            ]}
            actions={<ListActions />}
        >
            <PortfolioItemList />
        </RAList>
    );
};

export default List;
