import { Box, Text } from "@chakra-ui/react";
import React from "react";
import {
    List as RAList,
    ListProps as RAListProps,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    SimpleList,
    DeleteButton,
} from "react-admin";
import { TestimonialFields } from "src/api/constants";
import { RatingField } from "src/components/fields";
import { Panel, QuickFilter } from "src/components/modules";
import { useMedia } from "src/helpers";
import { Testimonial } from "src/types";

const List: React.FC<RAListProps> = ({ ...props }) => {
    const { isLargerThanOrIsTablet } = useMedia();

    return (
        <RAList
            {...props}
            filters={[
                <QuickFilter
                    label="Top Testimonials"
                    source="top"
                    defaultValue={true}
                />,
            ]}
        >
            {isLargerThanOrIsTablet ? (
                <Datagrid
                    rowClick="expand"
                    classes={{ expandedPanel: "list__expanded-panel" }}
                    expand={({ record }) => (
                        <Panel
                            paddingLeft="70px"
                            content={(record as unknown as Testimonial).content}
                        />
                    )}
                >
                    <TextField source={TestimonialFields.name} />
                    <RatingField source={TestimonialFields.rate} />
                    <DateField source={TestimonialFields.createdAt} />
                    <EditButton />
                    <DeleteButton />
                </Datagrid>
            ) : (
                <SimpleList
                    primaryText={<TextField source={TestimonialFields.name} />}
                    secondaryText={
                        <Box
                            d="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <RatingField source={TestimonialFields.rate} />
                            <Box>
                                <EditButton label="Modify" />
                            </Box>
                        </Box>
                    }
                    tertiaryText={
                        <Text color="gray.400" d="inline-block" float="right">
                            <DateField source={TestimonialFields.createdAt} />
                        </Text>
                    }
                />
            )}
        </RAList>
    );
};

export default List;
