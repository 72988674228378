import { BaseDTO } from "src/types";
import { WithId } from "./types";

export const withId = <T extends BaseDTO, U = T | T[]>(
    obj: U,
): U extends T[] ? WithId<T>[] : WithId<T> => {
    if (Array.isArray(obj))
        return obj.map((rec) => ({
            ...rec,
            id: rec._id,
        })) as any;
    return { ...obj, id: (obj as unknown as T)._id } as any;
};
