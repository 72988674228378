import { DataClient } from "src/api/client";
import {
    DeleteParams,
    UpdateParams,
    UpdateResult,
    DeleteResult,
    CreateResult,
    CreateParams,
} from "react-admin";
import { WithId, withId } from "src/helpers";
import { Cv } from "src/types/cv";

export const updateCv = async (
    client: DataClient,
    params: UpdateParams,
): Promise<UpdateResult<WithId<Cv>>> => {
    const response = await client.cv.updateOne(params.data);
    return { data: withId(response) };
};

export const createCv = async (
    client: DataClient,
    params: CreateParams,
): Promise<CreateResult<WithId<Cv>>> => {
    const response = await client.cv.create(params.data);
    return { data: withId(response) };
};

export const deleteCv = async (
    client: DataClient,
    params: DeleteParams,
): Promise<DeleteResult<WithId<Cv>>> => {
    const response = await client.cv.deleteOne(params.id.toString());
    return { data: withId(response) };
};
